import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { DataContext } from '../../context/DataContext'
import { decodeHTML } from '../../modules/utilities'
import ScrollToTop from  '../scrollToTop/ScrollToTop'

const Careers = () => {
  const { careersData } = useContext(DataContext)

  return (
    <section id="careers" className="careers">
      <Container>
        <Row>
          <iframe id="softr-12d35868-3acf-4e57-99d8-38a74b0a37ec" src="https://digimasters.softr.app/view-roles" width="100%" height="4000" scrolling="no" frameborder="0"></iframe>
<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.11/iframeResizer.min.js"></script>


        </Row>
      </Container>
    </section>
  );
}

export default Careers