import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import ScrollToTopOnMount from '../scrollToTop/ScrollToTopOnMount'
import { useParams, useHistory } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { decodeHTML } from '../../modules/utilities'

const CareerDetail = () => {
  const { careersData } = useContext(DataContext)
  const history = useHistory()

  // const [job, setJob] = useState()
  // let { jobId } = useParams()

  // useEffect(() => {
  //   if (careersData && jobId) {
  //     const id = parseFloat(jobId.split('_')[1])
  //     const thisJob = careersData.filter(item => item.job_id === id)[0]

  //     if (thisJob) {
  //       setJob(thisJob)
  //     } else {
  //       history.push('/careers')
  //     }
  //   }
  // }, [careersData, jobId, history])

  // return (
  //   <section id="portfolio-details" className="portfolio-details">
  //     <ScrollToTopOnMount />
  //     {job && 
  //       <div className="container">
  //         <h2 className="portfolio-title">{job.role_name}</h2>
  //         <Row>
  //           <Col xl={3} lg={4} md={12} className="portfolio-info" data-aos="fade-right">
  //             <ul>
  //               <li><strong>Location</strong>: {job.location_of_role}</li>
  //               <li><strong>Role Creation Date</strong>: {job.role_creation_date}</li>
  //               <li><strong>Role Start Date</strong>: {job.role_start_date}</li>
  //               <li><strong>Salary</strong>: {decodeHTML(job.role_salary)}</li>
  //               <li><strong>Role Length</strong>: {job.role_length}</li>
  //               <li><strong>Role Type</strong>: {job.role_type}</li>
  //             </ul>
  //           </Col>
  //           <Col xl={9} lg={8} md={12} className="portfolio-info" data-aos="fade-left">
  //             <div dangerouslySetInnerHTML={{__html: decodeHTML(job.role_long_desc)}} />
  //           </Col>
  //         </Row>
  //       </div>
  //     }
  //   </section>    
  // );
}

export default CareerDetail